import React from "react"
import Utils from "../../utils/node.utils"
import Order from "../../components/Order/Order"
import { StaticQuery, graphql } from "gatsby"
import {
  createContentList,
  createOverlay,
  createFailedOverlay,
  createErrorOverlay,
  createEmptyContentList,
  createFailedContentList,
  createErrorContentList,
} from "../../contentfunctions/order"

import Carousel1 from "../../images/faq/faq-min.jpeg"

const OrderIndexPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage(
    "nl",
    "/order",
    location.state,
    location.search
  )
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.orderContent.item.content
        )
        const img = {
          src: Carousel1,
          altText: contentMap.seo_title.value,
          caption: contentMap.seo_description.value,
        }
        return (
          <Order
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            breadCrumbs={["menu_home", "menu_order"]}
            location={location}
            originPage="order"
            language="nl"
            commonContentMap={commonContentMap}
            contentMap={contentMap}
            createContentList={createContentList}
            createOverlay={createOverlay}
            createFailedOverlay={createFailedOverlay}
            createErrorOverlay={createErrorOverlay}
            img={img}
            createEmptyContentList={createEmptyContentList}
            createFailedContentList={createFailedContentList}
            createErrorContentList={createErrorContentList}
            keywords={Utils.GetKeywordsFromTags(data.orderContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default OrderIndexPage

const pageQuery = graphql`
  {
    orderContent: orderContentNlnl {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentNlnl {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
